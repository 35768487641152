import React from "react"
import { Helmet } from "react-helmet"
import {
  Section,
  SectionHeading,
  SectionLedeDiv,
} from "../../styledComponents/section"
import Layout from "../../components/layout"
import SEO from "../../components/seo"

const Heading = ({ level = 1, location, children }) => (
  <SectionHeading as={`h${level}`} level={level} location={location}>
    {children}
  </SectionHeading>
)

const FeedbackThankyou = ({ data, location }) => {

  return (
    <>
    <Helmet>
      <meta name={`robots`} content={`noindex, nofollow`} />x
    </Helmet>
    <Layout displayNavBorder location={location}>
      <SEO title="Treatment and Workshop Feedback from" />
      <Section>
        <Heading level={1}>
          Feedback
        </Heading>
        <SectionLedeDiv>
          <p>Thank you for submitting your feedback form.</p>
        </SectionLedeDiv>       
      </Section>
    </Layout>
    </>
  )
}

export default FeedbackThankyou
